<template>
  <v-card raised>
    <v-row v-if="verticalLayout">
      <v-col cols="12" class="px-8">
        <v-img :src="imageSource" :style="imageMaxHeight" contain />
        <div
          class="text-center text-h6 vitrueDarkGrey--text font-weight-medium my-2"
          v-text="title"
        />
        <div id="divider" class="horizontal-bar" :style="barColourCSS" />
      </v-col>
      <v-col cols="12">
        <div
          :class="[
            fadedHeader || !showingResults
              ? 'text-center text-wrap text-subtitle-1 grey--text mb-2'
              : 'text-center text-wrap text-subtitle-1 black--text mb-2'
          ]"
          v-html="headerText"
        />
        <div
          v-show="!disabled && hideValueText == false"
          :class="[
            !showingResults
              ? 'text-center text-wrap text-subtitle-1 grey--text'
              : 'text-center text-wrap text-subtitle-1 black--text'
          ]"
        >
          <p class="ma-0">
            {{ preValueText }}
            <span :class="textColor">{{ valueText }}</span>
            {{ postValueText }}
            {{ supplementaryText }}
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!verticalLayout" class="mx-auto">
      <v-col cols="3" align-self="center">
        <v-img
          :src="imageSource"
          class="mx-auto"
          max-width="120px"
          height="86px"
          contain
        />
        <div
          class="text-center text-h6 vitrueDarkGrey--text font-weight-medium"
          v-text="title"
        />
      </v-col>
      <v-col cols="1">
        <div id="divider" class="vertical-bar" :style="barColourCSS" />
      </v-col>
      <v-col cols="8" align-self="center">
        <div
          :class="[
            fadedHeader || !showingResults
              ? 'text-wrap text-subtitle-1 grey--text mb-2'
              : 'text-wrap text-subtitle-1 black--text mb-2'
          ]"
          v-html="headerText"
        ></div>
        <div
          v-show="!disabled && hideValueText == false"
          :class="[
            !showingResults
              ? 'text-wrap text-subtitle-1 grey--text'
              : 'text-wrap text-subtitle-1 black--text'
          ]"
        >
          <p class="ma-0">
            {{ preValueText }}
            <span :class="textColor">{{ valueText }}</span>
            {{ postValueText }}
            {{ supplementaryText }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "InformationCard",
  props: {
    title: String,
    headerText: String,
    fadedHeader: Boolean,
    preValueText: String,
    valueText: String,
    postValueText: String,
    supplementaryText: String,
    imageUrl: String,
    sideHeader: Boolean,
    colour: String,
    showingResults: Boolean,
    hideValueText: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    liveResults: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    containerClass() {
      return this.liveResults ? "" : "pa-5";
    },
    imageMaxHeight() {
      return this.liveResults ? "max-height:12vh;" : "max-height:20vh;";
    },
    imageSource() {
      let suffix = this.disabled ? "Disabled.svg" : ".svg";
      return require("@/assets/images/" + this.imageUrl + suffix);
    },
    verticalLayout() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        default:
          return false;
      }
    },
    textColor() {
      let color = this.colour + "--text";
      return this.showingResults ? color : "error--text";
    },
    barColourCSS() {
      let colorToReturn;

      if (!this.showingResults) {
        colorToReturn = this.$vuetify.theme.currentTheme.error;
      } else if (this.disabled || this.colour === null) {
        colorToReturn = this.$vuetify.theme.currentTheme.vitrueGrey;
      } else {
        switch (this.colour) {
          case "red":
            colorToReturn = this.$vuetify.theme.currentTheme.vitrueRed;
            break;
          case "amber":
            colorToReturn = this.$vuetify.theme.currentTheme.vitrueOrange;
            break;
          case "green":
            colorToReturn = this.$vuetify.theme.currentTheme.vitrueDarkGreen;
            break;
        }
      }

      return {
        "--color": colorToReturn
      };
    }
  }
};
</script>

<style scoped>
#divider {
  background-color: var(--color);
}
</style>
