import { render, staticRenderFns } from "./WebcamInformationCard.vue?vue&type=template&id=4ba3d87e&scoped=true&"
import script from "./WebcamInformationCard.vue?vue&type=script&lang=js&"
export * from "./WebcamInformationCard.vue?vue&type=script&lang=js&"
import style0 from "./WebcamInformationCard.vue?vue&type=style&index=0&id=4ba3d87e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba3d87e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VImg,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ba3d87e')) {
      api.createRecord('4ba3d87e', component.options)
    } else {
      api.reload('4ba3d87e', component.options)
    }
    module.hot.accept("./WebcamInformationCard.vue?vue&type=template&id=4ba3d87e&scoped=true&", function () {
      api.rerender('4ba3d87e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/report/cards/WebcamInformationCard.vue"
export default component.exports