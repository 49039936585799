var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { raised: "" } },
    [
      _vm.verticalLayout
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-8", attrs: { cols: "12" } },
                [
                  _c("v-img", {
                    style: _vm.imageMaxHeight,
                    attrs: { src: _vm.imageSource, contain: "" }
                  }),
                  _c("div", {
                    staticClass:
                      "text-center text-h6 vitrueDarkGrey--text font-weight-medium my-2",
                    domProps: { textContent: _vm._s(_vm.title) }
                  }),
                  _c("div", {
                    staticClass: "horizontal-bar",
                    style: _vm.barColourCSS,
                    attrs: { id: "divider" }
                  })
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", {
                  class: [
                    _vm.fadedHeader || !_vm.showingResults
                      ? "text-center text-wrap text-subtitle-1 grey--text mb-2"
                      : "text-center text-wrap text-subtitle-1 black--text mb-2"
                  ],
                  domProps: { innerHTML: _vm._s(_vm.headerText) }
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.disabled && _vm.hideValueText == false,
                        expression: "!disabled && hideValueText == false"
                      }
                    ],
                    class: [
                      !_vm.showingResults
                        ? "text-center text-wrap text-subtitle-1 grey--text"
                        : "text-center text-wrap text-subtitle-1 black--text"
                    ]
                  },
                  [
                    _c("p", { staticClass: "ma-0" }, [
                      _vm._v(" " + _vm._s(_vm.preValueText) + " "),
                      _c("span", { class: _vm.textColor }, [
                        _vm._v(_vm._s(_vm.valueText))
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.postValueText) +
                          " " +
                          _vm._s(_vm.supplementaryText) +
                          " "
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.verticalLayout
        ? _c(
            "v-row",
            { staticClass: "mx-auto" },
            [
              _c(
                "v-col",
                { attrs: { cols: "3", "align-self": "center" } },
                [
                  _c("v-img", {
                    staticClass: "mx-auto",
                    attrs: {
                      src: _vm.imageSource,
                      "max-width": "120px",
                      height: "86px",
                      contain: ""
                    }
                  }),
                  _c("div", {
                    staticClass:
                      "text-center text-h6 vitrueDarkGrey--text font-weight-medium",
                    domProps: { textContent: _vm._s(_vm.title) }
                  })
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "1" } }, [
                _c("div", {
                  staticClass: "vertical-bar",
                  style: _vm.barColourCSS,
                  attrs: { id: "divider" }
                })
              ]),
              _c("v-col", { attrs: { cols: "8", "align-self": "center" } }, [
                _c("div", {
                  class: [
                    _vm.fadedHeader || !_vm.showingResults
                      ? "text-wrap text-subtitle-1 grey--text mb-2"
                      : "text-wrap text-subtitle-1 black--text mb-2"
                  ],
                  domProps: { innerHTML: _vm._s(_vm.headerText) }
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.disabled && _vm.hideValueText == false,
                        expression: "!disabled && hideValueText == false"
                      }
                    ],
                    class: [
                      !_vm.showingResults
                        ? "text-wrap text-subtitle-1 grey--text"
                        : "text-wrap text-subtitle-1 black--text"
                    ]
                  },
                  [
                    _c("p", { staticClass: "ma-0" }, [
                      _vm._v(" " + _vm._s(_vm.preValueText) + " "),
                      _c("span", { class: _vm.textColor }, [
                        _vm._v(_vm._s(_vm.valueText))
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.postValueText) +
                          " " +
                          _vm._s(_vm.supplementaryText) +
                          " "
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }